<template>
  <div>
    <!-- <div>lineId = {{lineId}}</div>
    <div>$RedeemNo = {{RedeemNo}}</div>
    <div>$liff_ID_Tracking = {{$liff_ID_Tracking}}</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineId: "",
      RedeemNo: "",
      lineProfile: {}
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Tracking })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          const queryString = decodeURIComponent(
            window.location.search
          ).replace("liff.state=?", "");
          this.$cookies.set("temp-list-state", queryString, 60 * 60 * 24);
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    this.lineId = this.$route.query.LineId;
    this.RedeemNo = this.$route.query.RedeemNo;

    if (!this.lineId) {
      const queryString = decodeURIComponent(window.location.search).replace(
        "?liff.state=",
        ""
      );
      const params = new URLSearchParams(queryString);
      if (params.has("LineId")) {
        const lineId = params.get("LineId");
        const RedeemNo = params.get("RedeemNo");
        this.RedeemNo = RedeemNo;
        this.lineId = lineId;
      } else {
        const queryString = decodeURIComponent(window.location.search).replace(
          "liff.state=?",
          ""
        );
        const params = new URLSearchParams(queryString);

        const RedeemNo = params.get("RedeemNo");
        const lineId = params.get("LineId");

        this.RedeemNo = RedeemNo;
        this.lineId = lineId;
      }
    }
  },

  methods: {
    async getToken() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let getCookies = null;
      let redeemNo = null;
      let lineId = null;
      if (this.$cookies.isKey("temp-list-state")) {
        getCookies = this.$cookies.get("temp-list-state");
        this.$cookies.remove("temp-list-state");
        const params = new URLSearchParams(getCookies);
        redeemNo = params.get("RedeemNo");
        lineId = params.get("LineId");
      }

      var raw = JSON.stringify({
        
        // LineID: this.lineId,
        // RedeemNo: this.RedeemNo ,
        LineID: this.lineId || lineId,
        RedeemNo: this.RedeemNo || redeemNo
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginfamilyclub/tracking`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (result.detail.url) {
              window.open(result.detail.url, "_self");
            } else {
              window.open(`${this.$PATH_SKL}`, "_self");
            }
          } else {
            window.open(`${this.$PATH_SKL}`, "_self");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
            } else {
              this.getToken();
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            // this.$router.push({
            //   path: "/policy",
            //   query: {
            //     lineid: lineId,
            //     term: true
            //   }
            // });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style></style>
